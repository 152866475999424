import { ArrowDoubleRight20Px } from "@locaisolutions/icons";
import {
  Box,
  Container,
  Stack,
  SvgIcon,
  Typography,
  styled
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useNavbar } from "~/hooks/useNavbar";

import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId,
  selectWorkstationAvailablePorts
} from "~/redux/selectors/workstationsSelectors";

import {
  // useGetInventoryMovementsStateQuery,
  useOpenPortMutation,
  usePostStartInventoryMovementsQuery,
  useRequestNextBinMutation
} from "~/redux/warehouse/autostoreGrid.hooks";

import { BinMaintenancePort } from "./BinMaintenancePort";
import { setTaskState } from "./binMaintenanceWorkstation.slice";
import { useTranslation } from "react-i18next";

interface BinMaintenanceWorkstation {
  viewTitle: string;
}

const ActionHeader = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(5)} auto`,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  width: "fit-content"
}));

export const BinMaintenanceWorkstation: React.FC<BinMaintenanceWorkstation> = (
  props
) => {
  const { viewTitle } = props;
  useNavbar({ viewTitle });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const ports = useAppSelector(selectWorkstationAvailablePorts);
  const { movementTaskState } = useAppSelector(
    (state) => state.binMaintenanceWorkstation
  );
  const [openPort] = useOpenPortMutation();
  const [requestNextBin] = useRequestNextBinMutation();
  const { errorToast } = useToast();

  // open ports for inventory movements
  const { error: startMovementsError, data: startMovementsData } =
    usePostStartInventoryMovementsQuery(
      // TODO: how to handle a page refresh
      autostoreGridId && selectedWorkstation?.id
        ? { autostoreGridId, workstationId: selectedWorkstation.id }
        : skipToken
    );

  // get list of inventory movements
  // const {
  //   data: tasks,
  //   isLoading: isLoadingGetMovementsState,
  //   isSuccess: isGetMovementsStateSuccess,
  //   refetch
  // } = useGetInventoryMovementsStateQuery(
  //   autostoreGridId && selectedWorkstation
  //     ? { autostoreGridId, workstationId: selectedWorkstation.id }
  //     : skipToken
  // );

  // open last port for bin cleaning on last port
  // TODO: this should eventually be by port type (ie "cleaning"),
  // but for now it seems that the first two ports are always utilized for inventory movements (that endpoint is by workstation, not port)
  const thirdPortId = selectedWorkstation?.ports[2]?.portId;
  useEffect(() => {
    if (!autostoreGridId || !thirdPortId) return;
    const openPortAndBin = async () => {
      await openPort({
        autostoreGridId,
        portId: thirdPortId,
        categoryId: 300
      });
      await requestNextBin({
        autostoreGridId,
        portId: thirdPortId
      });
    };
    openPortAndBin().catch((err) => {
      errorToast(getMessageFromRtkError(err));
    });
  }, [thirdPortId, autostoreGridId, openPort, errorToast, requestNextBin]);

  useEffect(() => {
    if (startMovementsError) {
      errorToast(getMessageFromRtkError(startMovementsError));
    }
  }, [startMovementsError, errorToast]);

  useEffect(() => {
    if (selectedWorkstation?.ports.length) {
      dispatch(setTaskState("MovingProduct"));
    }
  }, [selectedWorkstation?.ports, dispatch]);

  return (
    <Container>
      <Stack direction="column">
        <ActionHeader>
          <Stack direction="row">
            <SvgIcon
              component={ArrowDoubleRight20Px}
              sx={{ fontSize: "48px", alignSelf: "end" }}
            />
            <Typography variant="h3">
              {movementTaskState === "MovingProduct"
                ? t("move inventory")
                : t("confirm inventory move")}
            </Typography>
          </Stack>
        </ActionHeader>

        <Stack direction="row" justifyContent="space-around" px={3} spacing={3}>
          {ports.map((portId) => {
            return (
              <BinMaintenancePort
                key={portId}
                portId={portId}
                movementData={startMovementsData}
              />
            );
          })}
        </Stack>
      </Stack>
    </Container>
  );
};
