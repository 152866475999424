import AutomaticIcon from "@locaisolutions/icons/dist/icons20px/Automatic20Px";
import { Stack, Grid, Alert, useTheme } from "@mui/material";
import ASButton from "@locaisolutions/button";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { ConfirmationModal } from "~/components/ConfirmationModal";
import envConstants from "~/config/envConstants";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import {
  useGetAutostoreGridsQuery,
  useLazySyncGridStateQuery
} from "~/redux/warehouse/autostoreGrid.hooks";
import { useGridSelector } from "~/features/inventory/useGridSelector";

function SyncGridStateButton() {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [
    syncGridState,
    {
      isFetching: syncGridStateLoading,
      error: syncGridStateError,
      data: syncGridStateData
    }
  ] = useLazySyncGridStateQuery();
  const { data: autostoreGrids } = useGetAutostoreGridsQuery(undefined);

  const [isConfirmationModalOpen, setConfirmationModalVisibilityStatus] =
    useState(false);

  const isAdmin = useAppSelector(selectUserIsAdmin);

  const { autostoreGridOverride: selectedAutostoreGridId, gridSelectorButton } =
    useGridSelector();

  const selectedAutostoreGridName = useMemo(() => {
    return (
      autostoreGrids?.find(
        (el) => el.autostoreGridId === selectedAutostoreGridId
      )?.autostoreGridName || ""
    );
  }, [autostoreGrids, selectedAutostoreGridId]);

  const handleSyncGridState = async () => {
    if (!selectedAutostoreGridId) {
      return;
    }
    setConfirmationModalVisibilityStatus(true);

    await syncGridState({
      gridId: selectedAutostoreGridId
    });
  };
  const syncGridDataModalText = syncGridStateLoading
    ? t("syncing grid state in progress")
    : syncGridStateError
      ? t("sync grid state error")
      : syncGridStateData;

  return (
    <>
      {isAdmin &&
        envConstants.SHOW_SYNC_GRID_STATE_BUTTON === "true" &&
        !!autostoreGrids?.length && (
          <Stack
            data-testid="sync-grid-button-spacer"
            sx={{
              flexDirection: "row",
              ml: "10px",
              gap: 1
            }}
          >
            {gridSelectorButton}
            <ASButton
              style={{
                backgroundColor: palette.secondary.main,
                color: "black",
                fontWeight: 600
              }}
              startIcon={<AutomaticIcon />}
              onClick={handleSyncGridState}
              disabled={!selectedAutostoreGridId}
            >
              {t("sync grid state")}
            </ASButton>
          </Stack>
        )}
      {syncGridStateError && !syncGridStateLoading && (
        <Grid item xs={6} sx={{ margin: "15px 10px 5px 10px" }}>
          <Alert
            variant="outlined"
            severity="error"
            action={
              <ASButton onClick={handleSyncGridState}>{t("retry")}</ASButton>
            }
          >
            {t("sync grid state")}: {getMessageFromRtkError(syncGridStateError)}
          </Alert>
        </Grid>
      )}
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        confirmCb={() => {
          setConfirmationModalVisibilityStatus(false);
        }}
        closeCb={() => {
          setConfirmationModalVisibilityStatus(false);
        }}
        modalTitle={
          selectedAutostoreGridName
            ? `${t("sync grid state")} - ${selectedAutostoreGridName}`
            : t("sync grid state")
        }
        modalText={syncGridDataModalText}
        noCancelButton
        loader={syncGridStateLoading}
        disableConfirmButton={syncGridStateLoading}
        confirmText={t("close")}
      />
    </>
  );
}

export default SyncGridStateButton;
