import { parse, stringify } from "qs";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import useFlag from "~/config/flags";
import { setSelectedSummaries } from "~/features/inventory/inventory.slice";
import { isHomePageTypeIncluded } from "~/lib/helpers";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import {
  selectThisWorkstation,
  selectWorkstationHasInventoryRole
} from "~/redux/selectors/workstationsSelectors";

import { MainButton } from "./MainButton";

export function InventoryHoldsButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search }: { search: string } = useLocation();
  const inventoryV2Enabled = useFlag().enableInventoryV2;

  const clientConfig = useAppSelector(selectClientConfig);
  const isInventoryRoleFound = useAppSelector(
    selectWorkstationHasInventoryRole
  );

  const hasWorkstation = !!useAppSelector(selectThisWorkstation);

  const onInventoryHoldsClicked = () => {
    if (isInventoryRoleFound || !hasWorkstation) {
      navigate({
        // for clients that use inventory v2, we want the inventory-holds page to
        // be the inventory page filtered to inventory with holds rather than the actual inventory holds page
        pathname:
          isHomePageTypeIncluded(
            clientConfig.fc_autostore_homepages,
            "inventory v2"
          ) || inventoryV2Enabled
            ? "/inventoryv2/holds"
            : "/autostore-inventory-holds",
        search: stringify({
          ...parse(search, { ignoreQueryPrefix: true }),
          autostore: true
        })
      });
      dispatch(setSelectedSummaries([]));
    }
  };

  return (
    <MainButton
      alert={
        (!isInventoryRoleFound && t("no inventory role found")) || undefined
      }
      disabled={!isInventoryRoleFound && hasWorkstation}
      onClick={onInventoryHoldsClicked}
      variant="md"
    >
      {t("nav.link.inventory holds")}
    </MainButton>
  );
}
