import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { AxiosError, AxiosHeaders, AxiosRequestConfig } from "axios";

import { warehouseService } from "~/api/warehouse";

/**
 * Patch axios in as the fetch framework.
 *
 * https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#axios-basequery
 */
const axiosBaseQuery =
  (): BaseQueryFn<{
    url: string;
    method?: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    headers?: AxiosHeaders;
  }> =>
  async ({ url, method = "GET", data, params, headers }, { signal }) => {
    try {
      const result = await warehouseService({
        url: `/pepsi/api${url}`,
        method,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        params,
        headers,
        signal
      });
      return { data: result.data };
    } catch (axiosError: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          data: err.response?.data || err.message
        }
      };
    }
  };

export const pepsiApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  reducerPath: "pepsiApi"
});
