import dayjs from "dayjs";
import { generateUuid } from "~/lib/helpers";
import {
  FulfillmentCenterDto,
  InductionOrderCreateRequest,
  PickOrderRequest,
  WorkstationSummaryDto
} from "~/types/api";
import { CreateOrder } from "~/redux/public/orders.openApi";
import { groupBy } from "~/lib/shared";
import { ReceivedInventory } from "~/redux/public/inventory.openApi";

/***
 * Translate the Order to a Pick Order, a Pepsi-specific DTO.
 */
export const translateToPickOrder = (
  request: CreateOrder,
  fulfillmentCenter: FulfillmentCenterDto
): PickOrderRequest => {
  const totes = groupBy(request.lineItems, (li) => li.tote?.id || "");
  return {
    facility: fulfillmentCenter.abbreviation,
    location: "", // does this matter?
    messageId: request.id ?? generateUuid(),
    request: {
      pickOrder: {
        orderType: request.orderType,
        customerName: request.customerName ?? "",
        deliveryDate: dayjs(request.pickingEndTime).format("MM/DD/YYYY"),
        orderID: request.externalOrderId,
        priority: 0,
        numberOfCartons: [new Set(request.lineItems.map((li) => li.tote?.id))]
          .length,
        cartons: Object.entries(totes).map(([toteId, lineItems]) => ({
          cartonID: parseInt(toteId, 10),
          boxNumber: "1",
          boxSize: "S",
          numberOfItems: lineItems.length,
          items: lineItems.map((li) => ({
            layer: "",
            productCode: li.sku,
            quantity: li.quantity.value,
            uOM: li.quantity.unit === "ea" ? "E" : "C",
            uPC: li.sku
          }))
        }))
      }
    }
  };
};

export const translateToInductionOrder = (
  request: ReceivedInventory,
  fulfillmentCenter: FulfillmentCenterDto,
  workstation?: WorkstationSummaryDto
): InductionOrderCreateRequest => ({
  facility: fulfillmentCenter.abbreviation,
  location: workstation?.autostoreGridName || "gridname",
  messageId: generateUuid(),
  request: {
    inductionOrder: {
      stagingLocation: "CARIND1",
      cartID: `C${dayjs().format("MMDDYYYY")}`,
      palletID: `P${dayjs().format("MMDDYYYY")}`,
      details: request.receivings.map((r) => ({
        barCodedCaseProductCode: r.code ?? "",
        caseProductCode: r.code ?? "",
        caseCount: 3,
        codeDate: (
          dayjs(r.manufactureDate) || dayjs().subtract(5, "months")
        ).format(
          "MM/DD/YYYY" // Needs to be this format for Pepsi.
        ),
        lotNumber: r.lotNumber ?? "",
        inductProductCode: r.sku,
        uPC: r.sku,
        inductQuantity: r.count.value,
        inductUOM: r.count.unit === "ea" ? "E" : "C",
        sourcePalletID: "FrontendDev",
        holds: ""
      }))
    }
  }
});
