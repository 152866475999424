import { PickOrderRequest } from "~/types/api";
import { pepsiApi } from "./pepsiApi";

export const pickOrderApi = pepsiApi.injectEndpoints({
  endpoints: (builder) => ({
    createPepsiOrder: builder.mutation<void, PickOrderRequest>({
      query: (order) => ({
        url: `/v1/pickorder`,
        method: "POST",
        data: order
      })
    })
  }),
  overrideExisting: false
});
