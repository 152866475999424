import {
  ActionFromReducer,
  configureStore,
  ThunkAction,
  UnknownAction
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Store } from "redux";
import { persistStore } from "redux-persist";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { warehouseService } from "~/api/warehouse";
import envConstants from "~/config/envConstants";
import { mainReducer, rootReducer, StoreState } from "~/redux/reducers";
import { selectUsersFulfillmentCenterId } from "~/redux/selectors/storeSelectors";
import { warehouseApi } from "~/redux/warehouse/warehouseApi";

import { versionMiddleware } from "./versionMiddleware";
import { publicApi } from "~/redux/public/publicApi";
import { pepsiApi } from "~/redux/pepsi/pepsiApi";

export type AppDispatch = typeof store.dispatch;
export type AppSelector<R> = (state: StoreState) => R;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<StoreState> = useSelector;

export const selectState = (state: StoreState) => state;

type QubitActions = Exclude<
  ActionFromReducer<typeof mainReducer>,
  UnknownAction
>;

export type AppThunk<ReturnType = void> = ThunkAction<
  Exclude<ReturnType, Promise<unknown>>,
  StoreState,
  unknown,
  QubitActions
>;

export type AsyncAppThunk<ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  StoreState,
  unknown,
  QubitActions
>;

export function createReduxStore(preloadedState?: Partial<typeof rootReducer>) {
  const store = configureStore({
    reducer: mainReducer,
    devTools: true,
    preloadedState: preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat([
        versionMiddleware,
        warehouseApi.middleware,
        publicApi.middleware,
        pepsiApi.middleware
      ])
  });

  try {
    // workaround until redux-persist is updated to work with redux-5 (or replaced)
    const persistor = persistStore(store as unknown as Store);
    return { store, persistor };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    throw err;
  }
}

export type QubitStore = ReturnType<typeof createReduxStore>["store"];

export const { store, persistor } = createReduxStore();

setupListeners(store.dispatch);

store.subscribe(() => {
  if (!warehouseService && envConstants.MODE === "test") {
    return;
  }
  const state = store.getState();

  warehouseService.defaults.params = {
    clientId: state.login.profile ? getUserClientId(state.login.profile) : null,
    fulfillmentCenterId: selectUsersFulfillmentCenterId(state)
  };
});
