import { publicApi as api } from "./publicApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInventoryByInventoryId: build.query<
      GetInventoryByInventoryIdApiResponse,
      GetInventoryByInventoryIdApiArg
    >({
      query: (queryArg) => ({
        url: `/inventory/${queryArg.inventoryId}`,
        params: {
          clientId: queryArg.clientId,
          fulfillmentCenterId: queryArg.fulfillmentCenterId
        }
      })
    }),
    getInventory: build.query<GetInventoryApiResponse, GetInventoryApiArg>({
      query: (queryArg) => ({
        url: `/inventory`,
        params: {
          clientId: queryArg.clientId,
          fulfillmentCenterId: queryArg.fulfillmentCenterId,
          sku: queryArg.sku,
          expiresOnOrBeforeDate: queryArg.expiresOnOrBeforeDate,
          lotNumber: queryArg.lotNumber,
          binId: queryArg.binId,
          hasHold: queryArg.hasHold,
          offset: queryArg.offset,
          limit: queryArg.limit
        }
      })
    }),
    postInventoryBySkuBySkuPlaceHold: build.mutation<
      PostInventoryBySkuBySkuPlaceHoldApiResponse,
      PostInventoryBySkuBySkuPlaceHoldApiArg
    >({
      query: (queryArg) => ({
        url: `/inventory/by-sku/${queryArg.sku}/place-hold`,
        method: "POST",
        body: queryArg.inventorySkuHold,
        params: {
          clientId: queryArg.clientId,
          fulfillmentCenterId: queryArg.fulfillmentCenterId
        }
      })
    }),
    postInventoryBySkuBySkuRemoveHold: build.mutation<
      PostInventoryBySkuBySkuRemoveHoldApiResponse,
      PostInventoryBySkuBySkuRemoveHoldApiArg
    >({
      query: (queryArg) => ({
        url: `/inventory/by-sku/${queryArg.sku}/remove-hold`,
        method: "POST",
        body: queryArg.removeInventoryHoldBySkuRequest,
        params: {
          clientId: queryArg.clientId,
          fulfillmentCenterId: queryArg.fulfillmentCenterId
        }
      })
    }),
    getInventoryHolds: build.query<
      GetInventoryHoldsApiResponse,
      GetInventoryHoldsApiArg
    >({
      query: (queryArg) => ({
        url: `/inventory/holds`,
        params: {
          clientId: queryArg.clientId,
          fulfillmentCenterId: queryArg.fulfillmentCenterId
        }
      })
    }),
    getInventoryHoldsByInventoryId: build.query<
      GetInventoryHoldsByInventoryIdApiResponse,
      GetInventoryHoldsByInventoryIdApiArg
    >({
      query: (queryArg) => ({
        url: `/inventory/holds/${queryArg.inventoryId}`,
        params: {
          clientId: queryArg.clientId,
          fulfillmentCenterId: queryArg.fulfillmentCenterId
        }
      })
    }),
    postInventoryHoldsByInventoryIdPlace: build.mutation<
      PostInventoryHoldsByInventoryIdPlaceApiResponse,
      PostInventoryHoldsByInventoryIdPlaceApiArg
    >({
      query: (queryArg) => ({
        url: `/inventory/holds/${queryArg.inventoryId}/place`,
        method: "POST",
        body: queryArg.placeInventoryHold,
        params: {
          clientId: queryArg.clientId,
          fulfillmentCenterId: queryArg.fulfillmentCenterId
        }
      })
    }),
    postInventoryHoldsByInventoryIdRemove: build.mutation<
      PostInventoryHoldsByInventoryIdRemoveApiResponse,
      PostInventoryHoldsByInventoryIdRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/inventory/holds/${queryArg.inventoryId}/remove`,
        method: "POST",
        body: queryArg.removeInventoryHold,
        params: {
          clientId: queryArg.clientId,
          fulfillmentCenterId: queryArg.fulfillmentCenterId
        }
      })
    }),
    postInventorysnapshotsCreate: build.mutation<
      PostInventorysnapshotsCreateApiResponse,
      PostInventorysnapshotsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/inventorysnapshots/create`,
        method: "POST",
        body: queryArg.body,
        params: {
          clientId: queryArg.clientId,
          fulfillmentCenterId: queryArg.fulfillmentCenterId
        }
      })
    }),
    getInventorysnapshotsByInventorySnapshotId: build.query<
      GetInventorysnapshotsByInventorySnapshotIdApiResponse,
      GetInventorysnapshotsByInventorySnapshotIdApiArg
    >({
      query: (queryArg) => ({
        url: `/inventorysnapshots/${queryArg.inventorySnapshotId}`,
        params: {
          clientId: queryArg.clientId,
          fulfillmentCenterId: queryArg.fulfillmentCenterId
        }
      })
    }),
    postInventoryReceive: build.mutation<
      PostInventoryReceiveApiResponse,
      PostInventoryReceiveApiArg
    >({
      query: (queryArg) => ({
        url: `/inventory/receive`,
        method: "POST",
        body: queryArg.receivedInventory,
        params: {
          clientId: queryArg.clientId,
          fulfillmentCenterId: queryArg.fulfillmentCenterId
        }
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as inventoryApi };
export type GetInventoryByInventoryIdApiResponse =
  /** status 200 OK */ InventoryItem;
export type GetInventoryByInventoryIdApiArg = {
  /** AutoStore identifier for the inventory. */
  inventoryId: string;
  /** The AutoStore provided client ID. */
  clientId: string;
  /** The AutoStore provided fulfillment center ID. */
  fulfillmentCenterId: string;
};
export type GetInventoryApiResponse = /** status 200 OK */ InventoryItemPage;
export type GetInventoryApiArg = {
  /** The AutoStore provided client ID. */
  clientId: string;
  /** The AutoStore provided fulfillment center ID. */
  fulfillmentCenterId: string;
  /** When specified, returns only inventory matching the given SKU (optional). */
  sku?: string;
  /** When specified, returns only inventory that expires on or before the given date (optional). */
  expiresOnOrBeforeDate?: string;
  /** When specified, returns only inventory matching the given lot number (optional). */
  lotNumber?: string;
  /** When specified, returns only inventory located in the given AutoStore bin ID (optional). */
  binId?: string;
  /** When specified, returns inventory with a hold when true or inventory without a hold when false (optional). */
  hasHold?: boolean;
  /** The number of items to skip before returning results. Default is 0. */
  offset?: number;
  /** The number of items to return. */
  limit?: number;
};
export type PostInventoryBySkuBySkuPlaceHoldApiResponse =
  /** status 202 Accepted */ InventoryHoldBySkuResponse;
export type PostInventoryBySkuBySkuPlaceHoldApiArg = {
  /** The SKU to place a hold on. */
  sku: string;
  /** The AutoStore provided client ID. */
  clientId: string;
  /** The AutoStore provided fulfillment center ID. */
  fulfillmentCenterId: string;
  inventorySkuHold: InventorySkuHold;
};
export type PostInventoryBySkuBySkuRemoveHoldApiResponse =
  /** status 202 Accepted */ InventoryHoldBySkuResponse;
export type PostInventoryBySkuBySkuRemoveHoldApiArg = {
  /** The SKU to remove a hold from, if it has one */
  sku: string;
  /** The AutoStore provided client ID. */
  clientId: string;
  /** The AutoStore provided fulfillment center ID. */
  fulfillmentCenterId: string;
  removeInventoryHoldBySkuRequest: RemoveInventoryHoldBySkuRequest;
};
export type GetInventoryHoldsApiResponse = /** status 200 OK */ InventoryHold[];
export type GetInventoryHoldsApiArg = {
  /** The AutoStore provided client ID. */
  clientId: string;
  /** The AutoStore provided fulfillment center ID. */
  fulfillmentCenterId: string;
};
export type GetInventoryHoldsByInventoryIdApiResponse =
  /** status 200 OK */ InventoryHold;
export type GetInventoryHoldsByInventoryIdApiArg = {
  /** AutoStore identifier for the inventory. */
  inventoryId: string;
  /** The AutoStore provided client ID. */
  clientId: string;
  /** The AutoStore provided fulfillment center ID. */
  fulfillmentCenterId: string;
};
export type PostInventoryHoldsByInventoryIdPlaceApiResponse =
  /** status 204 No Content */ void;
export type PostInventoryHoldsByInventoryIdPlaceApiArg = {
  /** AutoStore identifier for the inventory. */
  inventoryId: string;
  /** The AutoStore provided client ID. */
  clientId: string;
  /** The AutoStore provided fulfillment center ID. */
  fulfillmentCenterId: string;
  placeInventoryHold: PlaceInventoryHold;
};
export type PostInventoryHoldsByInventoryIdRemoveApiResponse =
  /** status 204 No Content */ void;
export type PostInventoryHoldsByInventoryIdRemoveApiArg = {
  /** AutoStore identifier for the inventory. */
  inventoryId: string;
  /** The AutoStore provided client ID. */
  clientId: string;
  /** The AutoStore provided fulfillment center ID. */
  fulfillmentCenterId: string;
  removeInventoryHold: RemoveInventoryHold;
};
export type PostInventorysnapshotsCreateApiResponse =
  /** status 202 Accepted */ CreateInventorySnapshot;
export type PostInventorysnapshotsCreateApiArg = {
  /** The AutoStore provided client ID. */
  clientId: string;
  /** The AutoStore provided fulfillment center ID. */
  fulfillmentCenterId: string;
  body: (object | null) | CreateInventorySnapshotRequest;
};
export type GetInventorysnapshotsByInventorySnapshotIdApiResponse =
  /** status 200 OK */ InventorySnapshot;
export type GetInventorysnapshotsByInventorySnapshotIdApiArg = {
  /** The ID of inventory snapshot ID to get. */
  inventorySnapshotId: string;
  /** The AutoStore provided client ID. */
  clientId: string;
  /** The AutoStore provided fulfillment center ID. */
  fulfillmentCenterId: string;
};
export type PostInventoryReceiveApiResponse =
  /** status 202 Accepted */ ReceivedInventoryResponse;
export type PostInventoryReceiveApiArg = {
  /** The AutoStore provided client ID. */
  clientId: string;
  /** The AutoStore provided fulfillment center ID. */
  fulfillmentCenterId: string;
  receivedInventory: ReceivedInventory;
};
export type InventorySnapshotItemQuantity = {
  /** The inventory unit */
  unit?: string;
  /** The count of units in the bin */
  value?: number;
};
export type PurchaseOrderLineItem = {
  /** The AutoStore ID for the Purchase Order this inventory item is a part of */
  purchaseOrderId?: string;
  /** The Purchase Order Line Item Number for this inventory */
  purchaseOrderLineItemNumber?: string;
};
export type AutostoreBin = {
  /** The internal ID of the AutoStore grid containing the bin */
  gridId?: string;
  /** The  bin number of the AutoStore bin */
  binNumber?: number;
  /** An optional number of the compartment containing inventory in the bin */
  compartmentNumber?: number;
};
export type WarehouseBin = {
  /** The ID of the aisle containing the bin */
  aisle?: string;
  /** The ID of the shelf containing the bin */
  shelf?: string;
  /** The ID of the shelf bay containing the bin */
  shelfBay?: string;
  /** The ID of the pallet bay containing the bin */
  palletBay?: string;
  /** The position of the item */
  position?: string;
};
export type Bin = {
  /** The AutoStore ID for the bin containing the inventory */
  binId?: string;
  /** The bin type the inventory is contained in. If Autostore then the autostoreBin property will be populated, otherwise the warehouseBin will be populated. */
  binType?: string;
  /** An optional temperature zone the inventory is contained in: Ambient, Chilled, or Frozen */
  temperatureZone?: string | null;
  autostoreBin?: (object | null) | AutostoreBin;
  warehouseBin?: (object | null) | WarehouseBin;
};
export type InventoryHolds = {
  /** The type of hold */
  hold?: string;
  /** The hold details for external holds */
  details?: string | null;
};
export type InventoryItem = {
  /** The AutoStore ID for the inventory item */
  inventoryId?: string;
  /** The AutoStore ID for the variant of the product */
  variantId?: string;
  /** The brand name of the inventory item */
  brand?: string;
  /** The product name of the inventory item */
  product?: string;
  /** The SKU of the inventory item */
  sku?: string;
  quantity?: InventorySnapshotItemQuantity;
  /** The optional expiration date of the inventory item */
  expirationDate?: string | null;
  /** The optional manufacture date of the inventory item */
  manufactureDate?: string | null;
  /** An optional lot number for the inventory item */
  lotNumber?: string | null;
  purchaseOrderLineItem?: (object | null) | PurchaseOrderLineItem;
  bin?: Bin;
  holds?: InventoryHolds[];
};
export type ProblemDetails = {
  /** A URI reference that identifies the problem type */
  type?: string | null;
  /** A short, human-readable summary of the problem type */
  title: string;
  /** A human-readable explanation specific to this occurrence of the problem */
  detail?: string | null;
  /** A URI reference that identifies the specific occurrence of the problem */
  instance?: string | null;
};
export type InventoryItemPage = {
  /** The current page's URL */
  current_url?: string;
  /** The next page's URL, null if there are no more items. */
  next_url?: string | null;
  /** A collection of inventory items matching the filters */
  items?: InventoryItem[];
};
export type InventoryHoldBySkuResponse = {
  /** uri for the inventory hold */
  inventoryHoldUri?: string;
};
export type InventoryHoldReasonCode =
  | "archived"
  | "bin locked"
  | "bin not empty"
  | "bin problem"
  | "damaged"
  | "external"
  | "expiration"
  | "expired"
  | "expired pad time"
  | "expiration pad time"
  | "out of stock"
  | "over received"
  | "recalled"
  | "wrong compartment";
export type InventorySkuHold = {
  /** If specified, the hold is only placed on inventory matching the given expiration date. */
  expirationDate?: string | null;
  /** The hold details */
  details?: string | null;
  /** Type of hold being placed */
  reasonCode?: InventoryHoldReasonCode | null;
};
export type RemoveInventoryHoldBySkuRequest = {
  /** An optional expiration date to match when placing the hold */
  expirationDate?: string | null;
  /** Type of hold being removed */
  reasonCode?: InventoryHoldReasonCode | null;
};
export type InventoryHoldCount = {
  /** Number of the amount of inventory. */
  value?: number;
  /** Unit of the amount of inventory */
  unit?: string;
};
export type InventoryHoldProduct = {
  /** The AutoStore identifier of the Product. */
  id?: string;
  /** Name of the product */
  productName?: string;
  /** The Brand Name of the product */
  brand?: string;
  /** Stock Keeping Unit */
  sku?: string;
  /** Primary UPC or PLU for scanning barcode of the product. */
  upcOrPlu?: string;
  /** Secondary or alternate UPCs that also apply to this product */
  secondaryUpcs?: string[];
  /** Link to an image of the endpoint */
  image?: string;
};
export type InventoryHold = {
  /** AutoStore identifier for the inventory */
  inventoryId?: string;
  /** The type of hold placed on the inventory. “External” is used for holds originating externally. To be deprecated in favor of "holds" */
  hold?: string | null;
  /** If hold type is “External,” this can be populated with any extra details you want. To be deprecated in favor of "holds" */
  holdDetails?: string | null;
  holds?: {
    reasonCode?: InventoryHoldReasonCode;
    /** The externally added data about the hold */
    externalData?: string | null;
    /** The internally added notes about the hold */
    notes?: string | null;
    /** The time when the hold was placed */
    timestamp?: string;
  }[];
  /** Date that the inventory expires (or expired) */
  expiration?: string | null;
  /** Manufacture date for the inventory in ISO 8601 format. */
  manufactureDate?: string | null;
  /** Date and time that the inventory was put away. */
  putAway?: string;
  /** Lot number, if it was provided. */
  lotNumber?: string | null;
  count?: InventoryHoldCount;
  product?: InventoryHoldProduct;
  bin?: Bin;
};
export type PlaceInventoryHold = {
  /** Extra information about placing inventory on hold */
  details?: string | null;
  /** Type of hold being place */
  reasonCode?: string | null;
};
export type RemoveInventoryHold = {
  /** Type of hold being removed */
  reasonCode?: InventoryHoldReasonCode | null;
};
export type CreateInventorySnapshot = {
  /** An optional ID to identify the inventory snapshot generation task */
  inventorySnapshotId?: string;
  /** The URI of the snapshot once it is generated */
  inventorySnapshotUri?: string;
};
export type CreateInventorySnapshotRequest = {
  /** An optional AutoStore grid ID to limit the snapshot to. */
  autostoreGridId?: string | null;
  /** An optional identifier for the Inventory Snapshot. The value will be generated if not provided. */
  inventorySnapshotId?: string | null;
};
export type InventorySnapshot = {
  /** The unique ID of the inventory snapshot */
  inventorySnapshotId?: string;
  /** The offset of the last Inventory Adjustment Event that took place prior to the snapshot */
  lastInventoryAdjustmentEventOffset?: number;
  /** A collection of inventory items that make up the snapshot */
  inventory?: InventoryItem[];
};
export type ReceivedInventoryResponseFailureItem = {
  /** The error code. */
  errorCode?: string;
  /** The message detailing what's wrong. */
  errorMessage?: string;
};
export type ReceivedInventoryResponsePurchaseOrderLineItem = {
  /** Line Item number */
  purchaseOrderLineItemNumber?: string | null;
  /** Purchase Order number */
  purchaseOrderNumber?: string | null;
  /** AutoStore identifier for the Purchase Order. */
  purchaseOrderId?: string;
};
export type ReceivedInventoryResponseFailure = {
  /** The list of errors for the given inventory line item. */
  errors?: ReceivedInventoryResponseFailureItem[];
  purchaseOrderLineItem?:
    | (object | null)
    | ReceivedInventoryResponsePurchaseOrderLineItem;
  /** Stock Keeping Unit */
  sku?: string | null;
  /** Primary UPC or PLU for scanning barcode of the product */
  upcOrPlu?: string | null;
};
export type ReceivedInventoryResponseSuccess = {
  /** AutoStore identifier for the inventory created for the given line item of inventory. */
  inventoryId?: string;
  purchaseOrderLineItem?:
    | (object | null)
    | ReceivedInventoryResponsePurchaseOrderLineItem;
};
export type ReceivedInventoryResponse = {
  /** The top level status indicating success or failure of the group of inventory. */
  status?: string;
  /** All records that failed to process. Note: If FailAllIfAny was false, Successes and Failures may both be populated. */
  failures?: ReceivedInventoryResponseFailure[];
  /** All records that successfully processed and created inventory and putaway tasks. Note: If FailAllIfAny was false, Successes and Failures may both be populated. */
  successes?: ReceivedInventoryResponseSuccess[];
};
export type ReceivedInventoryItemCount = {
  unit: string;
  /** Amount of items to be added / subtracted (negative number) */
  value: number;
};
export type ReceivedInventoryItemDecantingRate = {
  unit?: string;
  /** The amount of the DecantingRate’s Units that are included in one count. */
  value?: number;
};
export type ReceivedInventoryItem = {
  /** Used if tracking a Batch Code, Pallet Code or Lot Code, etc. */
  code?: string | null;
  count: ReceivedInventoryItemCount;
  decantingRate?: (object | null) | ReceivedInventoryItemDecantingRate;
  /** Expiration date for the inventory in ISO 8601 format. Note: A Date can be passed in instead if preferred. */
  expiration?: string | null;
  /** Manufacture date for the inventory in ISO 8601 format. */
  manufactureDate?: string | null;
  /** Lot number */
  lotNumber?: string | null;
  /** Line Item number within the Purchase Order. Does not have to be unique. */
  purchaseOrderLineItemNumber?: string | null;
  /** Purchase Order number. Does not have to be unique. */
  purchaseOrderNumber?: string | null;
  /** Stock Keeping Unit */
  sku: string;
  /** Primary UPC or PLU for scanning barcode of the product */
  upcOrPlu?: string | null;
};
export type ReceivedInventory = {
  /** All of the inventory to receive and put away. */
  receivings: ReceivedInventoryItem[];
  /** Whether to fail the whole group of inventory if any of the group of inventory fails to validate or process. */
  failAllIfAnyFail?: boolean | null;
};
export const {
  useGetInventoryByInventoryIdQuery,
  useGetInventoryQuery,
  usePostInventoryBySkuBySkuPlaceHoldMutation,
  usePostInventoryBySkuBySkuRemoveHoldMutation,
  useGetInventoryHoldsQuery,
  useGetInventoryHoldsByInventoryIdQuery,
  usePostInventoryHoldsByInventoryIdPlaceMutation,
  usePostInventoryHoldsByInventoryIdRemoveMutation,
  usePostInventorysnapshotsCreateMutation,
  useGetInventorysnapshotsByInventorySnapshotIdQuery,
  usePostInventoryReceiveMutation
} = injectedRtkApi;
