import { Box, Container, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";

import { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { useAppDispatch, useAppSelector } from "~/app/store";

import { BigChoiceModal } from "~/components/BigChoiceModal";
import { ConfirmationModal } from "~/components/ConfirmationModal";
import { InventoryProductCard } from "~/components/InventoryProductCard";
import ScanningIndicator, {
  useScanIndicator
} from "~/components/ScanningIndicator";
import { createProductSearchOptions } from "~/config/algoliaConfig";
import { useDevCheats } from "~/hooks/useDevCheats";
import { useInactivityResetTimer } from "~/hooks/useInactivityResetTimer";
import { useNavbar } from "~/hooks/useNavbar";
import usePortStatus from "~/hooks/usePortStatus";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import { useToast } from "~/hooks/useToast";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { fetchWarehouseBinIds } from "~/lib/fetchWarehouseBinIds";
import { getEmptyCompartmentNumbers } from "~/lib/getEmptyBinCompartmentNumbers";
import { isAutostoreView, fetchAlgoliaSearch } from "~/lib/helpers";
import { useGridV2Subscription } from "~/lib/signalr";
import { nonNullable } from "~/lib/typeHelpers";
import usePromiseInterval from "~/lib/usePromiseIntervalEffect";

import {
  fetchPortStatus,
  closeBin,
  closePort,
  GetPortResponse,
  binAtPortEvent,
  binNotAtPort,
  fetchBinLogPublisherState,
  setPortStatus,
  setSelectedAutostoreBinId,
  getNextBinWithConfig,
  closeWorkstation,
  setCurrentEmptyBin
} from "~/redux/actions/autostore";
import { getCycleCountFrequencyByVid } from "~/redux/actions/cycleCounts";
import {
  getInventorySummaries,
  getVariantByVariantId,
  clearSelectedVariant,
  clearSelectedInventoryId,
  clearInventory,
  getInventoryReport,
  clearInventoryToModify,
  requestBinsForMultiport
} from "~/redux/actions/inventory";
import { setUserMessage } from "~/redux/actions/site";
import { StoreState } from "~/redux/reducers";
import { selectSelectedInventoryBin } from "~/redux/selectors/autostoreSelectors";
import {
  selectIsAdjustingBins,
  selectHoldTypeFilters,
  selectSelectedInventoryRows,
  selectSelectedSummaries,
  selectInventorySummariesToDisplay,
  selectSelectedInventoryAtPort,
  selectInventorySummaryToDisplay,
  selectAdjustingInventory
} from "~/redux/selectors/inventorySelectors";
import { warehouseApi } from "~/redux/warehouse/warehouseApi";
import { AutostoreEvent } from "~/types/api";

import { AutostorePutawayModal } from "./AutostorePutawayModal";
import { BottomActionButtons } from "./BottomActionButtons";
import CycleCountFrequencyButton from "./CycleCountFrequencyButton";
import { InventoryAdjustDialog } from "./InventoryAdjustDialog";
import { InventoryMultiPort } from "./InventoryMultiPort";
import { InventoryNavbarSearch } from "./InventoryNavbarSearch";
import { InventorySummaryHeader } from "./InventorySummaryHeader";
import { InventoryTables, limit } from "./InventoryTables";
import { TopActionButtons } from "./TopActionButtons";
import { getBinIds } from "./getBinIds";
import {
  incrementBinAtPortSeconds,
  resetBinAtPortSeconds,
  setAllInventoryPage,
  setIsAutostorePutawayDialogOpen,
  setIsAdjustingBins,
  setIsInventoryAdjustDialogOpen,
  setIsCreateInventoryButtonEnabled,
  setIsFetchingBin,
  setIsGetBinsLoading,
  setIsMovePanelOpen,
  setPortPollingActive,
  setSelectedAdjustingSummary,
  setSelectedCompartment,
  setSelectedSummaries
} from "./inventory.slice";
import { useAutocompleteSelect } from "./useAutocompleteSelect";
import { useNextEmptyBin } from "./useNextEmptyBin";
import { useViewType } from "./useViewType";
import useFlag from "~/config/flags";
import { useGridSelectorWrapper } from "./useGridSelectorWrapper";

type BinSearchData = {
  autostore_bin_number: number;
  warehouse_bin_ids: Guid[];
  type?: string;
};
const mapStateToProps = (state: StoreState) => ({
  selectedAutostoreGridId: state.workstations.siteWorkstation?.autostoreGridId,
  selectedPortId: state.workstations.sitePortId,
  selectedVariant: state.inventory.selectedVariant,
  clientId: getUserClientId(state.login.profile),
  portState: state.autostore.portState,
  selectedAutostoreBinId: state.autostore.selectedAutostoreBinId,
  requestedAutostoreBin: state.autostore.requestedAutostoreBin || null,
  inventoryReports: state.inventory.inventoryReport,
  usersFulfillmentCenter: state.store.usersFulfillmentCenter,
  siteAllPortIds: state.workstations.siteAllPortIds,
  thisWorkstationId: state.workstations.siteWorkstation?.id,
  initialFrequency: state.cycleCounts.cycleCountFrequency?.frequency,
  nextBinInventoryByPort: state.autostore.nextBinInventoryByPort,
  portStateByPort: state.autostore.portStateByPort,
  siteWorkstation: state.workstations.siteWorkstation,
  userMessages: state.site.userMessages,
  nextEmptyBinByPort: state.autostore.nextEmptyBinByPort
});

const connector = connect(mapStateToProps, {
  getInventorySummaries,
  getVariantByVariantId,
  clearSelectedInventoryId,
  fetchPortStatus,
  closeBin,
  closePort,
  binAtPortEvent,
  binNotAtPort,
  fetchBinLogPublisherState,
  getInventoryReport,
  setPortStatus,
  clearInventoryToModify,
  getCycleCountFrequencyByVid,
  setUserMessage,
  setSelectedAutostoreBinId,
  getNextBinWithConfig,
  requestBinsForMultiport,
  setCurrentEmptyBin
});
type InventoryMainInheritedProps = { viewTitle?: string };
type PropsFromRedux = ConnectedProps<typeof connector>;
export type InventoryMainProps = PropsFromRedux & InventoryMainInheritedProps;

export type BinOrProductResult = {
  type: string;
  variantId?: Guid;
  binId?: Guid;
  displayText: string;
};

function InventoryMain(props: InventoryMainProps) {
  const {
    userMessages,
    selectedVariant,
    selectedAutostoreGridId: selectedGridIdFromRedux,
    selectedPortId,
    clientId,
    portState,
    selectedAutostoreBinId,
    inventoryReports,
    usersFulfillmentCenter,
    siteAllPortIds,
    thisWorkstationId,
    initialFrequency,
    viewTitle,
    nextBinInventoryByPort,
    portStateByPort,
    siteWorkstation,
    nextEmptyBinByPort,
    clearInventoryToModify,
    clearSelectedInventoryId,
    binNotAtPort
  } = props;

  // hooks
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const shouldListenToGridEvents = useShouldListenToGridEvents();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locationInfo = useLocation();
  const { pathname, search } = locationInfo;
  const isAutostoreInventoryView = isAutostoreView(search);
  const { isHoldsView, isProductView, viewType } = useViewType();
  const { variantId: variantIdParam, binNumber: binNumberParam } = useParams<{
    variantId?: string;
    binNumber?: string;
  }>();
  const handleAutocompleteSelect = useAutocompleteSelect();
  const { gridSelectorButton, autostoreGridOverride } =
    useGridSelectorWrapper();

  const selectedAutostoreGridId = siteWorkstation
    ? selectedGridIdFromRedux
    : autostoreGridOverride;

  const { setMenuItems, setWarnings } = useNavbar({
    centerComponent: useMemo(() => <InventoryNavbarSearch />, []),
    viewTitle,
    rightComponent: gridSelectorButton
  });

  const nextEmptyBin = useNextEmptyBin();

  const isAdjustingBins = useAppSelector(selectIsAdjustingBins);
  const holdTypeFilters = useAppSelector(selectHoldTypeFilters);
  const inventorySummaries = useAppSelector(
    (state) => state.inventory.inventorySummaries
  );
  const selectedSummaries = useAppSelector(selectSelectedSummaries);
  const allInventoryPage = useAppSelector(
    (state) => state.inventoryNew.allInventoryPage
  );
  const binAtPortSeconds = useAppSelector(
    (state) => state.inventoryNew.binAtPortSeconds
  );
  const isAutostorePutawayDialogOpen = useAppSelector(
    (state) => state.inventoryNew.isAutostorePutawayDialogOpen
  );
  const isGetBinsLoading = useAppSelector(
    (state) => state.inventoryNew.isGetBinsLoading
  );
  const selectedAdjustingInventory = useAppSelector(selectAdjustingInventory);
  const isChangeBinConfigModalOpen = useAppSelector(
    (state) => state.inventoryNew.isChangeBinConfigModalOpen
  );
  const isFetchingBin = useAppSelector(
    (state) => state.inventoryNew.isFetchingBin
  );
  const isCreateInventoryButtonEnabled = useAppSelector(
    (state) => state.inventoryNew.isCreateInventoryButtonEnabled
  );
  const portPollingActive = useAppSelector(
    (state) => state.inventoryNew.portPollingActive
  );
  const isInventoryAdjustDialogOpen = useAppSelector(
    (state) => state.inventoryNew.isInventoryAdjustDialogOpen
  );
  const inventorySummaryToDisplay = useAppSelector(
    selectInventorySummaryToDisplay
  );
  const selectedRows = useAppSelector(selectSelectedInventoryRows);
  const selectedInventoryAtPort = useAppSelector(selectSelectedInventoryAtPort);
  const summariesToDisplay = useAppSelector(selectInventorySummariesToDisplay);

  const [scannedBarcode, setScannedBarcode] = useState<string | null>(null);

  // adjust specific bin
  const [specificBinAtPort, setSpecificBinAtPort] = useState<number | null>(
    null
  );

  const [scanChoices, setScanChoices] = useState<BinOrProductResult[]>(
    [] as BinOrProductResult[]
  );
  const [showScanChoiceModal, setShowScanChoiceModal] =
    useState<boolean>(false);

  const [isNavigateAwayModalOpen, setIsNavigateAwayModalOpen] = useState(false);
  const [isNoInventoryBin, setIsNoInventoryBin] = useState(false);
  const [scanState, setScanState] = useScanIndicator();
  const [searchedBinIds, setSearchedBinIds] = useState<Guid[] | null>(null);

  const currentSelectedBin = useAppSelector(selectSelectedInventoryBin);
  const currentSelectedPortId = currentSelectedBin?.portId;

  const { areAllPortsReady, firstPort, portStateByPortArray } = usePortStatus(
    portStateByPort,
    siteAllPortIds,
    currentSelectedPortId
  );

  const offset = (allInventoryPage - 1) * limit;

  const isAnySelect: boolean = selectedRows.length >= 1;
  const allSelectedBinTypeAreAutostore = selectedSummaries.every(
    (summary) => summary?.binType === "autostore"
  );
  const selectedBinNumbers = selectedSummaries
    ?.map((summary) => summary?.autostoreBinNumber)
    .filter(nonNullable);

  const onInactivityModalClose = useCallback(() => {
    dispatch(setIsAutostorePutawayDialogOpen(false));
    clearInventoryToModify();
    dispatch(setIsInventoryAdjustDialogOpen(false));
    dispatch(setIsMovePanelOpen(false));
  }, [clearInventoryToModify, dispatch]);

  const { restartInactivityTimer } = useInactivityResetTimer({
    onInactivityModalClose
  });

  useDevCheats({
    isPortPolling: portPollingActive,
    showAutostoreStatus: isAutostoreInventoryView
  });

  const clearSelectedData = () => {
    dispatch(clearSelectedVariant());
    props.clearSelectedInventoryId();
    props.setCurrentEmptyBin(null);
  };

  const resetView = (resetInv = true) => {
    if (resetInv) {
      clearSelectedData();
      dispatch(clearInventory());
      dispatch(setSelectedSummaries([]));
    }
    if (!shouldListenToGridEvents) {
      dispatch(setPortPollingActive(false));
    } else {
      props.binAtPortEvent();
    }
    dispatch(setIsFetchingBin(false));
    dispatch(setIsCreateInventoryButtonEnabled(true));
    dispatch(setSelectedAdjustingSummary(null));
    dispatch(setAllInventoryPage(1));
  };

  // adjusting specific bin
  useEffect(() => {
    const specificBinResponse = Object.values(nextBinInventoryByPort).find(
      (bin) => bin.nextBinResponse?.openBinResponse.binId === specificBinAtPort
    );
    const emptyBins = specificBinResponse
      ? getEmptyCompartmentNumbers(specificBinResponse.nextBinResponse)
      : [];
    if (
      isAutostorePutawayDialogOpen &&
      specificBinResponse &&
      !emptyBins?.length
    ) {
      errorToast(t("bin is full"));
      dispatch(setIsGetBinsLoading(false));
      dispatch(setIsAutostorePutawayDialogOpen(false));
      dispatch(setIsAdjustingBins(false));
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(closeWorkstation());
    }
    if (
      specificBinAtPort &&
      nextBinInventoryByPort &&
      specificBinResponse &&
      emptyBins?.length &&
      emptyBins.length > 0
    )
      dispatch(
        setSelectedCompartment({
          bin: specificBinResponse.nextBinResponse,
          compartmentNumber: emptyBins[0] - 1 // select first empty compartment
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificBinAtPort, nextBinInventoryByPort]);

  // component did unmount
  useEffect(
    () => () => {
      resetView(false);
      if (areAllPortsReady && shouldListenToGridEvents) {
        props.binNotAtPort();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    props.setSelectedAutostoreBinId(
      inventorySummaryToDisplay?.autostoreBinNumber || null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventorySummaryToDisplay]);

  // EFFECT 1: stop polling when bin arrives //
  useEffect(() => {
    if (!shouldListenToGridEvents && areAllPortsReady) {
      dispatch(setPortPollingActive(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areAllPortsReady]);

  useEffect(() => {
    if (
      pathname === "/inventoryv2/bin" ||
      pathname === "/inventoryv2/product"
    ) {
      dispatch(clearSelectedVariant());
      clearSelectedInventoryId();
      dispatch(clearInventory());
    }
    if (!shouldListenToGridEvents) {
      dispatch(setPortPollingActive(false));
    } else if (areAllPortsReady) binNotAtPort();

    dispatch(setIsFetchingBin(false));
    dispatch(setAllInventoryPage(1));
  }, [
    pathname,
    shouldListenToGridEvents,
    areAllPortsReady,
    dispatch,
    binNotAtPort,
    clearSelectedInventoryId
  ]);

  const handleGetInventorySummaries = useCallback(
    async (args: {
      variantId?: Guid;
      searchedBinIds?: Guid | Guid[];
      offsetZero?: boolean;
      holds?: string[];
    }) => {
      const { variantId, searchedBinIds, offsetZero, holds } = args;
      let autostoreGridIdToSearch: Guid | undefined;

      // assumption: if accessed via autostore menu, only show inventory within the selected grid
      // if accessed from the side menu, show all inventory for the client
      if (isAutostoreInventoryView) {
        autostoreGridIdToSearch = selectedAutostoreGridId;
      }
      await dispatch(
        getInventorySummaries({
          variantId,
          binId: searchedBinIds,
          limit,
          offset: offsetZero ? 0 : offset,
          autostoreGridId: autostoreGridIdToSearch,
          hasHold: isHoldsView ? true : undefined,
          hold: holds
        })
      );
    },
    [
      dispatch,
      isHoldsView,
      offset,
      selectedAutostoreGridId,
      isAutostoreInventoryView
    ]
  );

  const setBinInvSummaries = async (
    binNumberParam: string,
    holds?: string[]
  ) => {
    if (selectedAutostoreGridId && clientId && usersFulfillmentCenter) {
      const warehouseBinIds = await fetchWarehouseBinIds({
        selectedAutostoreGridId,
        clientId,
        usersFulfillmentCenter,
        binNumberParam
      });
      if (warehouseBinIds) {
        setSearchedBinIds(warehouseBinIds);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        handleGetInventorySummaries({
          searchedBinIds: warehouseBinIds,
          offsetZero: true,
          holds
        });
      }
    }
  };

  const handleRefetchInventory = (holds?: string[], offsetZero?: boolean) => {
    if (variantIdParam) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      handleGetInventorySummaries({
        variantId: variantIdParam,
        holds,
        offsetZero
      });
    } else if (binNumberParam) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      setBinInvSummaries(binNumberParam, holds);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      handleGetInventorySummaries({ holds, offsetZero });
    }
  };

  // * fetch inventory, etc when url changes
  useEffect(() => {
    if (variantIdParam) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      handleGetInventorySummaries({
        variantId: variantIdParam
      });
    } else if (binNumberParam) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      setBinInvSummaries(binNumberParam);
    } else if (limit || allInventoryPage)
      handleRefetchInventory(holdTypeFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantIdParam, binNumberParam, limit, allInventoryPage]);

  // if we're adjusting bins, don't reset the page
  useEffect(() => {
    if (isAdjustingBins) return;
    handleRefetchInventory(holdTypeFilters, true);
    dispatch(setAllInventoryPage(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdTypeFilters, isAdjustingBins]);

  // get variant info for selected inventory, or variant from params if no inventory records
  const searchVariantId: Guid | null =
    (isAdjustingBins && selectedInventoryAtPort?.variantId) ||
    variantIdParam ||
    inventorySummaryToDisplay?.variantId ||
    null;

  useEffect(() => {
    if (searchVariantId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getVariantByVariantId(searchVariantId);
    } else if (selectedAdjustingInventory) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getVariantByVariantId(selectedAdjustingInventory.variantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVariantId, selectedAdjustingInventory, variantIdParam]);

  // get inventory report for selected variant. Used for total qty/total committed
  useEffect(() => {
    if (selectedVariant) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getInventoryReport(selectedVariant.variantId);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getCycleCountFrequencyByVid(selectedVariant.variantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant]);

  const createBinSearchOptions = (binRecord: BinSearchData[]) =>
    binRecord.map((b: { autostore_bin_number: number }) => ({
      displayText: String(b.autostore_bin_number),
      type: "bin"
    }));

  interface ScanObj {
    scanType: "bin result" | "product result" | "products result";
    binOrProductResult?: BinOrProductResult;
    productsResult?: BinOrProductResult[];
  }

  const useBackendForAlgoliaSearch = useFlag().useBackendForAlgoliaSearch;

  useKeyDownHandler();
  useBarcodeScanner<ScanObj | null>({
    disabled: isAutostorePutawayDialogOpen,
    findScanMatch: async (buffer: string) => {
      // prevent scan from firing when adjusting bins
      if (isAdjustingBins && selectedRows.length) return null;
      restartInactivityTimer();
      setScannedBarcode(buffer);
      setScanState("success");
      const hits = clientId
        ? await fetchAlgoliaSearch(useBackendForAlgoliaSearch, clientId, buffer)
        : [];
      // alert user if no results are returned from algolia
      if (!hits.length)
        errorToast(
          t("no product exists for") +
            buffer +
            ". " +
            t("please set product aside")
        );
      const hitsAsAutocompleteRecords = createProductSearchOptions({
        hits,
        exactUpcMatchFilter: buffer
      });
      if (hitsAsAutocompleteRecords.length === 1) {
        return {
          scanType: "product result",
          binOrProductResult: hitsAsAutocompleteRecords[0]
        };
      }
      if (hitsAsAutocompleteRecords.length > 1) {
        return {
          scanType: "products result",
          productsResult: hitsAsAutocompleteRecords
        };
      }

      const binData = await getBinIds({
        searchText: buffer,
        usersClientId: clientId,
        usersFulfillmentCenter,
        usersAutostoreGridId: selectedAutostoreGridId
      });

      setSearchedBinIds(binData[0]?.warehouse_bin_ids);
      const binsAsAutocompleteRecords = createBinSearchOptions(binData);
      const matchedBinRecord = binsAsAutocompleteRecords.find(
        (obj) => obj.displayText === buffer
      );
      if (matchedBinRecord) {
        return {
          scanType: "bin result",
          binOrProductResult: matchedBinRecord
        };
      }
      return null;
    },
    processScanMatch: (scanObj) => {
      if (!scanObj) return null;
      if (isAutostorePutawayDialogOpen) return null;
      if (scanObj.scanType === "products result" && scanObj.productsResult) {
        setScanChoices(scanObj.productsResult);
        setShowScanChoiceModal(true);
        return null;
      }

      const matchedItem = scanObj.binOrProductResult;
      if (!matchedItem) {
        return null;
      }
      setScanState("success");
      if (scanObj.scanType === "product result") {
        navigate({
          pathname: `/inventoryv2/product/${matchedItem.variantId as string}`,
          search
        });
      } else if (scanObj.scanType === "bin result") {
        dispatch(clearSelectedVariant());
        navigate({
          pathname: `/inventoryv2/bin/${matchedItem.displayText}`,
          search
        });
      }
      return null;
    },
    deps: [viewType]
  });

  // PORT POLLING //
  // if log publisher enabled, poll port and log publisher state
  const handleFetchPortStatus = async (portId?: number) => {
    const portStatus = await props.fetchPortStatus({ portId });
    if (portStatus && selectedAutostoreGridId && !!portStatus.selectedBin) {
      props.setPortStatus(portStatus);
      try {
        const binStateResponse = await props.fetchBinLogPublisherState(
          selectedAutostoreGridId,
          portStatus.selectedBin
        );
        if (
          binStateResponse?.binState.binMode === "O" &&
          binStateResponse.binState.portId === (portId || selectedPortId)
        ) {
          if (
            isInventoryAdjustDialogOpen &&
            portStatus.selectedBin === firstPort?.getPortResponse.selectedBin
          ) {
            dispatch(setIsCreateInventoryButtonEnabled(true));
            dispatch(setIsFetchingBin(false));
          }
          if (!isAdjustingBins) {
            props.binAtPortEvent();
          }
        }
      } catch {
        resetView(false);
        if (isAutostorePutawayDialogOpen || isInventoryAdjustDialogOpen) {
          dispatch(setIsAutostorePutawayDialogOpen(false));
          dispatch(setIsInventoryAdjustDialogOpen(false));
        }
      }
    }
  };

  const passiveGridPolling =
    !shouldListenToGridEvents && !areAllPortsReady && isAdjustingBins;

  // POLL FOR BIN AT PORT INFORMATION IF EVENT ISN'T RECEIVED LONGER THAN 7 SECONDS
  usePromiseInterval(
    async () => {
      dispatch(incrementBinAtPortSeconds());
      // for grids in passive mode, dont bother waiting for events for 7 seconds
      if (siteWorkstation && (binAtPortSeconds > 7 || passiveGridPolling)) {
        await Promise.all(
          siteWorkstation.ports.map((port) => {
            const portStateResponse =
              portStateByPort[port.portId]?.getPortResponse;
            if (
              !portStateResponse?.isReady &&
              portStateResponse?.selectedBin !== 0
            )
              return handleFetchPortStatus(port.portId);
            return Promise.resolve();
          })
        );
      }
    },
    1000,
    !areAllPortsReady &&
      isAutostoreInventoryView &&
      (isAdjustingBins || !isCreateInventoryButtonEnabled)
  );

  useEffect(() => {
    if (!isAdjustingBins && areAllPortsReady && binAtPortSeconds > 0) {
      dispatch(resetBinAtPortSeconds());
      dispatch(setIsCreateInventoryButtonEnabled(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areAllPortsReady]);

  const resetPage = useCallback(
    async (clearSelectedSummaries = true) => {
      await dispatch(closeWorkstation());
      dispatch(clearSelectedVariant());
      dispatch(clearInventory());
      if (clearSelectedSummaries) dispatch(setSelectedSummaries([]));
      dispatch(setIsAdjustingBins(false));
      dispatch(setSelectedAdjustingSummary(null));
      dispatch(setPortPollingActive(false));
      dispatch(setIsFetchingBin(false));
      dispatch(setAllInventoryPage(1));
      await handleGetInventorySummaries({});
    },
    [dispatch, handleGetInventorySummaries]
  );

  // if warehouse returns a 1015, then no more tasks in task group
  // reset state and refetch inventory summaries
  useEffect(() => {
    if (!userMessages) return;
    const messageExists: boolean | null = userMessages.some(
      (messageObj) =>
        messageObj?.title
          ?.toLowerCase()
          .includes("there is nothing left to pick") || null
    );

    // TO DO: REPLACE LOGIC THAT DEPENDS ON ERROR MESSAGE
    if (!!userMessages.length && messageExists) {
      props.setUserMessage({
        title: "There are no tasks remaining in the current task-group.",
        severity: "success",
        autohideDuration: 2000,
        origin: "inventory/NO_TASKS_REMAINING"
      });

      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(closeWorkstation()).then(() => resetPage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMessages]);

  // Cleanup task group on page render (first hit on page or on page refresh)
  useEffect(() => {
    const closeTaskGroupandWorkstation = async () => {
      if (selectedPortId === null) return;
      await dispatch(closeWorkstation());
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    closeTaskGroupandWorkstation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClosePort = useCallback(async () => {
    const data = await dispatch(fetchPortStatus());

    if (data && (data.selectedBin > 0 || data.selectedTask > 0)) {
      setIsNavigateAwayModalOpen(true);
    } else {
      await dispatch(closePort());
      dispatch(setIsFetchingBin(false));
      await resetPage();
    }
  }, [dispatch, resetPage]);

  useEffect(() => {
    setMenuItems(
      isAutostoreInventoryView && selectedAutostoreGridId && selectedPortId
        ? [
            {
              textContent: t("close port"),
              actionCb: handleClosePort
            }
          ]
        : []
    );
  }, [
    handleClosePort,
    search,
    selectedAutostoreGridId,
    selectedPortId,
    setMenuItems,
    isAutostoreInventoryView,
    t
  ]);

  useEffect(() => {
    setWarnings([
      ...(isAutostoreInventoryView && !selectedPortId
        ? [t("no autostore port selected")]
        : [])
    ]);
  }, [search, selectedPortId, setWarnings, t, isAutostoreInventoryView]);

  const handleExitGetBins = async () => {
    if (selectedAutostoreGridId && portState) {
      const { selectedTask, isReady } = portState;
      if (isReady) {
        await props.closeBin({
          binId: portState.selectedBin,
          taskId: selectedTask
        });
      }
      await props.closePort();
    }
    clearInventoryToModify();
    dispatch(setIsAdjustingBins(false));
    dispatch(setPortPollingActive(false));
    dispatch(setIsFetchingBin(false));
  };

  const handleGetBinsMultiPort = async (
    specificBinIds?: number[]
  ): Promise<void> => {
    dispatch(setIsGetBinsLoading(true));
    if (!selectedAutostoreGridId || !thisWorkstationId || !siteWorkstation)
      return;

    await props.requestBinsForMultiport(
      specificBinIds || selectedBinNumbers,
      selectedAutostoreGridId,
      thisWorkstationId
    );

    if (!isFetchingBin) {
      dispatch(setIsFetchingBin(true));
    }

    for (const portId of siteAllPortIds) {
      try {
        await props.getNextBinWithConfig({ portId });
      } catch {
        return;
      }

      await props
        .fetchPortStatus({ portId })
        .then((getPortResponse: GetPortResponse | void) => {
          if (!getPortResponse) return;
          const portIsOpen = getPortResponse.mode
            .toLowerCase()
            .includes("open");
          const portIsReady = getPortResponse.isReady;

          if (!portIsReady && shouldListenToGridEvents) {
            props.binNotAtPort();
          }

          if (portIsOpen && portIsReady) {
            try {
              setIsNoInventoryBin(true);
              if (!shouldListenToGridEvents) {
                dispatch(setPortPollingActive(true));
              } else {
                props.binNotAtPort();
              }
              dispatch(setIsFetchingBin(false));
            } catch {
              resetView(false);
              dispatch(resetBinAtPortSeconds());
              if (isInventoryAdjustDialogOpen) {
                dispatch(setIsInventoryAdjustDialogOpen(false));
              }
            }
          }
        });
    }
  };

  const handleCreateBinInventory = () => {
    if (selectedAutostoreGridId && binNumberParam && thisWorkstationId) {
      dispatch(clearSelectedVariant());
      dispatch(setIsGetBinsLoading(true));
      dispatch(setIsAutostorePutawayDialogOpen(true));
      // turn on port polling
      dispatch(setIsAdjustingBins(true));
      // fetch current bin
      // how do we fetch the current selected bin when we can search multiples?

      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      handleGetBinsMultiPort([Number(binNumberParam)]).then(() => {
        dispatch(setIsGetBinsLoading(false));
        if (binNumberParam) setSpecificBinAtPort(Number(binNumberParam));
      });
    }
  };

  // listen for portState changes
  useEffect(() => {
    if (!areAllPortsReady) return;
    if (
      (!shouldListenToGridEvents && areAllPortsReady) ||
      (areAllPortsReady && shouldListenToGridEvents)
    ) {
      dispatch(setPortPollingActive(false));
      dispatch(setIsFetchingBin(false));
    } else if (areAllPortsReady && isNoInventoryBin) {
      dispatch(setPortPollingActive(false));
      dispatch(setIsFetchingBin(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNoInventoryBin, areAllPortsReady]);

  const gridSub = (data: AutostoreEvent) => {
    if (
      [
        "LiftCellLocked",
        "LiftCellUnlocked",
        "CellLocked",
        "CellUnlocked"
      ].includes(data.case)
    ) {
      dispatch(warehouseApi.util.invalidateTags(["autostore bin"]));
    }

    if (
      data.case !== "BinModeChange" ||
      data.event.binMode !== "O" ||
      data.event.gridId !== selectedAutostoreGridId ||
      !data.event.portId ||
      !siteAllPortIds.includes(data.event.portId)
    )
      return;

    if (data.event.binId === selectedAutostoreBinId) {
      props.binAtPortEvent();
    }

    // this means we are waiting for empty bins
    // if we receive a bin where all compartments have inventory
    // then we should close the bin and get the next one
    // TODO: make this work when polling
    const emptyBinForPort = nextEmptyBinByPort[data.event.portId];
    if (
      isChangeBinConfigModalOpen &&
      emptyBinForPort &&
      emptyBinForPort.autostoreBinCompartments.every(
        (c) => !c.isAutostoreBinCompartmentEmpty
      )
    ) {
      Sentry.captureMessage(
        `[InventoryV2] Bin was brought to induction but inventory records were found in bin ${data.event.binId}`,
        "info"
      );

      void (async () => {
        await props.closeBin({
          portId: data.event.portId,
          binId: data.event.binId,
          taskId: emptyBinForPort.openBinResponse.taskId
        });
        dispatch(setIsAdjustingBins(true));
        await nextEmptyBin(data.event.portId);
        await handleFetchPortStatus(data.event.portId);
      })();
    }
  };

  useGridV2Subscription(gridSub);

  useEffect(() => {
    if (
      portStateByPortArray.length &&
      portStateByPortArray.every(
        (port) =>
          port.getPortResponse.mode === "OPEN" &&
          !!port.getPortResponse?.selectedBin
      )
    ) {
      dispatch(setIsCreateInventoryButtonEnabled(true));
    }
    // if there's only one putaway default to that task
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventorySummaries]);

  const handleScanChoiceSelect = (value: string) => {
    const selectedProductResult: BinOrProductResult | undefined =
      scanChoices.find((scanChoice) => scanChoice.variantId === value);

    if (selectedProductResult) {
      handleAutocompleteSelect(selectedProductResult);
    }
  };

  const handleFetchAllInventory = async (clearSelectedSummaries = true) => {
    clearSelectedData();
    await resetPage(clearSelectedSummaries);
    navigate({
      pathname: `/inventoryv2/${isHoldsView ? "holds" : ""}`,
      search
    });
    await handleGetInventorySummaries({ offsetZero: true });
  };

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          padding: 2,
          display: "grid",
          gridTemplateRows: "auto 1fr auto auto",
          gridTemplateColumns: "0.4fr 1fr",
          gap: 2
        }}
      >
        <Box sx={{ gridRow: "1/-1" }}>
          <InventoryProductCard
            variantId={
              isAnySelect || isProductView
                ? selectedVariant?.variantId
                : undefined
            }
            addClickCb={() => null}
            inventoryReports={inventoryReports}
            usersFulfillmentCenter={usersFulfillmentCenter}
            initialCycleCountFrequency={initialFrequency}
            hideAddInventory
          />
          <CycleCountFrequencyButton
            disabled={
              !selectedVariant?.variantId || selectedSummaries.length > 1
            }
            initialFrequency={initialFrequency}
          />
        </Box>

        {/* Right Side - Buttons + Inventory Table + MultiPort */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack>
            <InventorySummaryHeader
              selectedVariant={selectedVariant}
              isAdjustingBins={isAdjustingBins}
              handleCreateBinInventory={handleCreateBinInventory}
              handleFetchAllInventory={handleFetchAllInventory}
              isAutostoreInventoryView={isAutostoreInventoryView}
              binNumberParam={binNumberParam}
              variantIdParam={variantIdParam}
              searchedBinRecord={searchedBinIds}
              binCreateInventoryDisabled={inventorySummaries.some(
                (summary) => summary.isBinFull === true
              )}
              selectedAutostoreGridId={selectedAutostoreGridId}
            />
          </Stack>
          <Stack flexDirection="row" gap={2}>
            <TopActionButtons
              isAdjustingBins={isAdjustingBins}
              isGetBinsLoading={isGetBinsLoading}
              handleFetchAllInventory={() =>
                handleFetchAllInventory(isAdjustingBins)
              }
            />
          </Stack>
        </Stack>
        <InventoryTables
          portStateByPortArray={portStateByPortArray}
          summariesToDisplay={summariesToDisplay}
          selectedAutostoreGridId={selectedAutostoreGridId}
        />
        {siteWorkstation && (
          <BottomActionButtons
            isAdjustingBins={isAdjustingBins}
            isGetBinsDisabled={
              !isAnySelect || !allSelectedBinTypeAreAutostore || isFetchingBin
            }
            selectedSummaries={selectedSummaries}
            inventorySummaryToDisplay={inventorySummaryToDisplay}
            selectedInventoryAtPort={selectedInventoryAtPort}
            handleGetBinsMultiPort={handleGetBinsMultiPort}
            handleGetInventorySummaries={handleGetInventorySummaries}
            searchedBinIds={searchedBinIds}
          />
        )}
        {/* multi ports display as full workstation, span the full page here */}
        <InventoryMultiPort />
      </Container>

      {/* Modals */}
      <AutostorePutawayModal
        handleRefetchInventory={handleRefetchInventory}
        resetView={resetView}
      />
      <InventoryAdjustDialog
        handleFetchAllInventory={handleFetchAllInventory}
        handleFetchPortStatus={handleFetchPortStatus}
        onClose={async () => {
          if (!isAdjustingBins) {
            await handleExitGetBins();
          }
        }}
      />
      <BigChoiceModal
        isOpen={showScanChoiceModal}
        choiceTitle="Select Variant"
        choices={scanChoices.map((scanChoice) => ({
          label: scanChoice.displayText,
          value: scanChoice.variantId || ""
        }))}
        selectCb={(selected) => {
          handleScanChoiceSelect(selected);
        }}
        closeCb={() => setShowScanChoiceModal(false)}
      />
      <ConfirmationModal
        isOpen={isNavigateAwayModalOpen}
        confirmCb={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          props.closePort();
          if (areAllPortsReady) {
            props.binNotAtPort();
          }
          setIsNavigateAwayModalOpen(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          resetPage();
        }}
        closeCb={() => {
          setIsNavigateAwayModalOpen(false);
        }}
        modalTitle={t("are you sure you want to close the workstation")}
        modalText=""
      />
      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={scannedBarcode}
        placeholderText={t("scan product or bin barcode")}
      />
    </>
  );
}
export default connector(InventoryMain);
