import { InductionOrderCreateRequest } from "~/types/api";
import { pepsiApi } from "./pepsiApi";

export const inductionOrderApi = pepsiApi.injectEndpoints({
  endpoints: (builder) => ({
    createPepsiInductionOrder: builder.mutation<
      void,
      InductionOrderCreateRequest
    >({
      query: (order) => ({
        url: `/v1/inductionorder`,
        method: "POST",
        data: order
      })
    })
  }),
  overrideExisting: false
});
